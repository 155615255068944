import { Component } from "@angular/core";
import { AppRoutes } from "../app.routes";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { default as STRINGS } from "src/assets/strings.json";
import { NewLegitimationComponent } from "../partner-legitimations/new-legitimation/new-legitimation.component";

type NavigationCard = {
  title: string;
  content: string;
  link?: string;
  disabled?: true;
  click?: any;
};

@Component({
  selector: "app-start-page",
  templateUrl: "./start-page.component.html",
  styleUrls: ["./start-page.component.css"],
})
export class StartPageComponent {
  readonly cardTitle: string = STRINGS.newLegitimationCardTitle;
  readonly cardContent: string = STRINGS.newLegitimationCardContent;

  readonly navigationCards: NavigationCard[] = [
    {
      title: STRINGS.legitimationsCardTitle,
      content: STRINGS.legitimationsCardContent,
      link: AppRoutes.LEGITIMATION,
    },
  ];

  constructor(private readonly dialog: MatDialog, private router: Router) {}

  public openNewLegitimationDialog(): void {
    this.dialog
      .open<NewLegitimationComponent, void>(NewLegitimationComponent)
      .afterClosed()
      .subscribe((id) => {
        if (id !== undefined) {
          this.router.navigateByUrl(AppRoutes.LEGITIMATION + "/" + id);
        }
      });
  }
}
