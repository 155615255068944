import { Component, OnInit } from "@angular/core";
import { default as STRINGS } from "../../../assets/strings.json";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PartnerLegitimationService } from "../services/partner-legitimation.service";
import { defaultInputForm, InputForm } from "../model/InputForm";
import { PdfDocument } from "../model/PdfDocument";
import { CustomValuesDialogComponent } from "./custom-values-dialog/custom-values-dialog.component";
import { CustomValue } from "../model/CustomValues";

@Component({
  selector: "app-new-legitimation",
  templateUrl: "./new-legitimation.component.html",
  styleUrls: ["./new-legitimation.component.css"],
})
export class NewLegitimationComponent implements OnInit {
  readonly createButtonSave: string = STRINGS.genericSaveButtonLabel;
  readonly createButtonCancel: string = STRINGS.genericCancelButtonLabel;
  readonly createTitle: string = STRINGS.createLegitimationLabel;
  readonly attachPdfsButton: string = STRINGS.attachPdfsButtonLabel;
  readonly addCustomValuesButton: string = STRINGS.addCustomValuesButtonLabel;
  readonly inputForms: InputForm[] = defaultInputForm;
  errorMsg = STRINGS.genericSavingFailedMessage;
  creationForm!: FormGroup;
  isCurrentlyLoading = false;
  hasErrorLoading = false;
  qesPdfs: FileList | undefined;
  pdfsToBeSigned: PdfDocument[] = [];
  customValuesList: CustomValue[] = [];

  constructor(
    private readonly partnerLegitimationService: PartnerLegitimationService,
    private readonly dialogRef: MatDialogRef<NewLegitimationComponent>,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.creationForm = new FormGroup({
      product: new FormControl("SMART_PHOTO_IDENT"),
      purpose: new FormControl("EMPTY"),
      firstName: new FormControl(null),
      surname: new FormControl(null),
      street: new FormControl(null),
      houseNumber: new FormControl(null),
      postalCode: new FormControl(null),
      placeOfResidence: new FormControl(null),
      dateOfBirth: new FormControl(null),
      placeOfBirth: new FormControl(null),
      nameAtBirth: new FormControl(null),
      nationality: new FormControl(null),
      documentNumber: new FormControl(null),
      issuingCountry: new FormControl(null),
      dateOfIssue: new FormControl(null),
      dateOfExpiry: new FormControl(null),
      issuingAuthority: new FormControl(null),
      telephoneNumber: new FormControl({
        value: "",
        disabled: true,
      }),
      emailAddress: new FormControl({
        value: "",
        disabled: true,
      }),
    });
  }

  public qesSelected(event: string, id: string): void {
    if (id === "product") {
      if (event === "SMART_QUALIFIED_SIGN") {
        this.creationForm.controls.telephoneNumber.enable();
        this.creationForm.controls.emailAddress.enable();
      } else {
        this.creationForm.controls.telephoneNumber.reset();
        this.creationForm.controls.emailAddress.reset();
        this.creationForm.controls.telephoneNumber.disable();
        this.creationForm.controls.emailAddress.disable();
        this.pdfsToBeSigned = [];
      }
    }
  }

  public selectedFiles(event: any): void {
    this.qesPdfs = event.target.files;
    this.createBase64QesPdfs(this.qesPdfs);
  }

  public createLegitimation(): void {
    this.isCurrentlyLoading = true;
    const { value, valid } = this.creationForm;
    const productValue = value.product;
    const purposeValue = value.purpose;
    delete value.product;
    delete value.purpose;
    const formattedCustomValues = this.formatCustomValues();

    if (valid) {
      purposeValue === "EMPTY"
        ? this.partnerLegitimationService
            .postLegitimationDetails({
              product: productValue,
              customer: value,
              customValues: formattedCustomValues,
              pdfsToBeSigned: this.pdfsToBeSigned,
            })
            .subscribe(
              (id) => {
                this.isCurrentlyLoading = true;
                this.dialogRef.close(id);
              },
              (err) => {
                this.errorMsg = this.errorMsg.concat("\n", err.message);
                this.hasErrorLoading = true;
              }
            )
        : this.partnerLegitimationService
            .postLegitimationDetails({
              product: productValue,
              purpose: purposeValue,
              customer: value,
              customValues: formattedCustomValues,
              pdfsToBeSigned: this.pdfsToBeSigned,
            })
            .subscribe(
              (id) => {
                this.isCurrentlyLoading = true;
                this.dialogRef.close(id);
              },
              (err) => {
                this.errorMsg = this.errorMsg.concat("\n", err.error);
                this.hasErrorLoading = true;
              }
            );
    } else {
      this.creationForm.markAllAsTouched();
    }
  }

  public getLabelFromEnum(enumVal: any): string {
    return ((STRINGS.qIdProductLabels as any)[enumVal] ||
      (STRINGS.legitimationPurposeLabels as any)[enumVal] ||
      enumVal) as string;
  }

  public openAddCustomValueDialog(): void {
    this.dialog
      .open(CustomValuesDialogComponent, {
        data: this.customValuesList,
        disableClose: true,
      })
      .afterClosed()
      .subscribe(
        (customValueList: CustomValue[]) => {
          if (customValueList !== undefined) {
            this.customValuesList = customValueList;
          }
        },
        (err) => {
          this.hasErrorLoading = true;
          console.error("An error occurred while editing custom values.", err);
        }
      );
  }

  private formatCustomValues() {
    return this.customValuesList.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {} as { [key: string]: string });
  }

  private createBase64QesPdfs(pdfs: FileList | undefined): void {
    if (pdfs === undefined) {
      return;
    }
    for (let i = 0; i < pdfs.length; i++) {
      // Base64 convertion
      const reader = new FileReader();
      reader.readAsDataURL(pdfs[i]);
      reader.onload = () => {
        const base64Dataurl = reader.result;

        if (typeof base64Dataurl === "string") {
          const plainBase64 = base64Dataurl.replace(
            "data:application/pdf;base64,",
            ""
          );
          this.pdfsToBeSigned.push({
            fileName: pdfs[i].name,
            base64Pdf: plainBase64,
          });
        }
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
      };
    }
  }
}
