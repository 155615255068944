import { Injectable } from "@angular/core";
import { StringsUtil } from "../../shared/util/strings.util";
import { environment } from "../../../environments/environment";
import { LegitimationImageType, VideoType } from "../model/Legitimation";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PartnerLegitimationMapper } from "./partner-legitimation.mapper";
import { SafeUrl } from "@angular/platform-browser";
import { AuthService } from "src/app/login/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class PartnerLegitimationAdapter {
  constructor(
    private httpClient: HttpClient,
    private readonly partnerLegitimationMapper: PartnerLegitimationMapper,
    private authService: AuthService
  ) {}

  public getLegitimation(id: string): Observable<string> {
    return this.httpClient.get(
      StringsUtil.format(environment.api.legitimation.details, id),
      this.getHttpOptions()
    );
  }

  public postLegitimation(body: object): Observable<string> {
    return this.httpClient.post(
      environment.api.legitimation.create,
      body,
      this.getHttpOptions()
    );
  }

  public getLegitimationImage(
    id: string,
    imageType: LegitimationImageType
  ): Observable<SafeUrl | null> {
    return this.httpClient
      .get(
        StringsUtil.format(environment.api.legitimation.image, id, imageType),
        { ...this.getHttpOptions(), responseType: "blob" }
      )
      .pipe(
        map((legitimationImageDto) =>
          this.partnerLegitimationMapper.mapBlobToSafeDataUrl(
            legitimationImageDto
          )
        )
      );
  }

  public getVideoUrl(id: string, videoType: VideoType): string {
    return StringsUtil.format(
      environment.api.legitimation.video,
      id,
      videoType
    );
  }

  public getLegitimationFilesZip(id: string): Observable<Blob | null> {
    return this.httpClient.get(
      StringsUtil.format(environment.api.legitimation.zip, id),
      {
        ...this.getHttpOptions(),
        responseType: "blob",
      }
    );
  }
  public getHttpOptions() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.authService.getClientAuthorizationHeader(),
      }),
      responseType: "text" as const,
    };
  }

  public getAllLegitimations(): Observable<any[]> {
    return this.httpClient
      .get(environment.api.legitimation.getAll, {
        ...this.getHttpOptions(),
        responseType: "json",
      })
      .pipe(map((res) => res as any[]));
  }
}
