import { default as STRINGS } from "../../../assets/strings.json";
import { StringsUtil } from "../../shared/util/strings.util";

export type InputForm = {
  /**
   * The identifier of the form
   *
   * @example: product
   */
  id: string;
  /**
   * The displayed title of the form
   *
   * @example: Product
   */
  title: string;
  /**
   * The input type of the form
   *
   * @example: select
   */
  type: string;
  /**
   * The actual values of the form
   *
   * @example: SMART_VIDEO_IDENT
   */
  values?: string[];
  /**
   * Option to disable the form
   *
   * @example: false
   */
  disabled: boolean;
};

export const defaultInputForm: InputForm[] = [
  {
    id: "product",
    title: STRINGS.product,
    type: "select",
    values: [
      "SMART_PHOTO_IDENT",
      "SMART_AGENT_FAST_TRACK",
      "SMART_CAR_IDENT_FAST_TRACK",
      "SMART_PHOTO_IDENT_FAST_TRACK",
      "SMART_CAR_IDENT",
      "SMART_VIDEO_IDENT",
      "SMART_QUALIFIED_SIGN",
    ],
    disabled: false,
  },
  {
    id: "purpose",
    title: STRINGS.purpose,
    type: "select",
    values: [
      "EMPTY",
      "ACCOUNT_OPENING",
      "CURRENT_ACCOUNT_OPENING",
      "OVERNIGHT_MONEY_OPENING",
      "TIME_DEPOSIT_OPENING",
      "APPLICATION_FOR_INSTALLMENT_LOAN",
      "INSTANT_LOAN_APPLICATION",
      "LOAN_APPLICATION",
      "FIXED_DEPOSIT",
      "PILOT_PHASE",
      "DRAWING_CAPITAL_INVESTMENT",
    ],
    disabled: false,
  },
  {
    id: "firstName",
    title: StringsUtil.getTitle("firstName"),
    type: "text",
    disabled: false,
  },
  {
    id: "surname",
    title: StringsUtil.getTitle("surname"),
    type: "text",
    disabled: false,
  },
  {
    id: "street",
    title: StringsUtil.getTitle("street"),
    type: "text",
    disabled: false,
  },
  {
    id: "houseNumber",
    title: StringsUtil.getTitle("houseNumber"),
    type: "text",
    disabled: false,
  },
  {
    id: "postalCode",
    title: StringsUtil.getTitle("postalCode"),
    type: "number",
    disabled: false,
  },
  {
    id: "placeOfResidence",
    title: StringsUtil.getTitle("placeOfResidence"),
    type: "text",
    disabled: false,
  },
  {
    id: "dateOfBirth",
    title: StringsUtil.getTitle("dateOfBirth"),
    type: "date",
    disabled: false,
  },
  {
    id: "placeOfBirth",
    title: StringsUtil.getTitle("placeOfBirth"),
    type: "text",
    disabled: false,
  },
  {
    id: "nameAtBirth",
    title: StringsUtil.getTitle("nameAtBirth"),
    type: "text",
    disabled: false,
  },
  {
    id: "nationality",
    title: StringsUtil.getTitle("nationality"),
    type: "text",
    disabled: false,
  },
  {
    id: "documentNumber",
    title: StringsUtil.getTitle("documentNumber"),
    type: "text",
    disabled: false,
  },
  {
    id: "issuingCountry",
    title: StringsUtil.getTitle("issuingCountry"),
    type: "text",
    disabled: false,
  },
  {
    id: "dateOfIssue",
    title: StringsUtil.getTitle("dateOfIssue"),
    type: "date",
    disabled: false,
  },
  {
    id: "dateOfExpiry",
    title: StringsUtil.getTitle("dateOfExpiry"),
    type: "date",
    disabled: false,
  },
  {
    id: "issuingAuthority",
    title: StringsUtil.getTitle("issuingAuthority"),
    type: "text",
    disabled: false,
  },
  {
    id: "telephoneNumber",
    title: StringsUtil.getTitle("telephoneNumber"),
    type: "tel",
    disabled: true,
  },
  {
    id: "emailAddress",
    title: StringsUtil.getTitle("emailAddress"),
    type: "email",
    disabled: true,
  },
];
