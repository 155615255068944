import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";
import { CustomValue } from "../../model/CustomValues";
import { default as STRINGS } from "../../../../assets/strings.json";

@Component({
  selector: "app-custom-values-dialog",
  templateUrl: "./custom-values-dialog.component.html",
  styleUrls: ["./custom-values-dialog.component.css"],
})
export class CustomValuesDialogComponent implements OnInit {
  readonly customValuesTitle: string = STRINGS.customValuesTitle;
  readonly keyLabel: string = STRINGS.customValuesKeyLabel;
  readonly valueLabel: string = STRINGS.customValuesValueLabel;
  readonly resetBtnLabel: string = STRINGS.genericResetLabel;
  readonly doneBtnLabel: string = STRINGS.genericDoneLabel;
  readonly duplicateKeyErrorText: string = STRINGS.duplicateKeysNotAllowedError;
  readonly keyRequiredErrorText: string = STRINGS.keyRequiredError;
  readonly valueRequiredErrorText: string = STRINGS.valueRequiredError;
  readonly maxAllowedValues = 3;
  readonly maxInputLength = 80;
  keyFC!: FormControl;
  valueFC!: FormControl;
  customValues: CustomValue[] = [];

  constructor(
    private dialogRef: MatDialogRef<CustomValuesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: CustomValue[]
  ) {
    this.customValues = data;
  }

  ngOnInit(): void {
    this.keyFC = new FormControl("", [Validators.required]);
    this.valueFC = new FormControl("", [Validators.required]);
    this.keyFC.valueChanges.subscribe((key) => {
      if (this.isKeyDuplicate(key)) {
        this.keyFC.setErrors({ duplicate: true });
      }
    });
    this.checkCustomValuesSize();
  }

  onAddClick(): void {
    const cv: CustomValue = {
      key: this.keyFC.value,
      value: this.valueFC.value,
    };
    if (this.customValues.length < this.maxAllowedValues) {
      this.customValues.push(cv);
      this.resetFormValues();
    }
    this.checkCustomValuesSize();
  }

  onRemoveClick(keyToRemove: string): void {
    const index = this.customValues.findIndex((cv) => cv.key === keyToRemove);

    if (index !== -1) {
      this.customValues.splice(index, 1);
    }
    this.checkCustomValuesSize();
  }

  onResetClick(): void {
    this.resetFormValues();
    this.customValues.length = 0;
  }

  onDoneClick(): void {
    this.dialogRef.close(this.customValues);
    this.resetFormValues();
  }

  private isKeyDuplicate(key: string): boolean {
    return this.customValues.some((item) => item.key === key);
  }

  private checkCustomValuesSize() {
    if (this.customValues.length >= this.maxAllowedValues) {
      this.keyFC.disable();
      this.valueFC.disable();
    } else {
      this.keyFC.enable();
      this.valueFC.enable();
    }
  }

  private resetFormValues(): void {
    this.keyFC.reset("");
    this.valueFC.reset("");
  }
}
