import { Component } from "@angular/core";
import { default as STRINGS } from "../../assets/strings.json";
import { AuthService } from "../login/services/auth.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent {
  readonly headerTitle: string = STRINGS.headerTitle + environment.envTile;
  readonly logoutButtonLabel: string = STRINGS.loginPageLogoutButtonLabel;

  constructor(readonly authService: AuthService) {}

  logout(): void {
    this.authService.logout();
  }
}
