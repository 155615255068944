<mat-toolbar md-medium-tall class="toolbar">
  <img src="./assets/img/qundo-big.svg" alt="Qundo" id="logo" />
  <span [innerHTML]="headerTitle"></span>
  <span class="flexExpand"></span>
  <button
    mat-raised-button
    class="logout-button"
    *ngIf="authService.isLoggedIn()"
    (click)="logout()"
  >
    {{ logoutButtonLabel }}
  </button>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
