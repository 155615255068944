<h2 class="mat-dialog-title">{{ customValuesTitle }}</h2>
<mat-dialog-content>
  <form>
    <mat-form-field>
      <mat-label>{{ keyLabel }}</mat-label>
      <input
        matInput
        [formControl]="keyFC"
        [disabled]="customValues.length >= this.maxAllowedValues"
        [maxLength]="maxInputLength"
      />
      <mat-error *ngIf="keyFC.hasError('required')">{{
        keyRequiredErrorText
      }}</mat-error>
      <mat-error *ngIf="keyFC.hasError('duplicate')">{{
        duplicateKeyErrorText
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ valueLabel }}</mat-label>
      <input
        matInput
        [formControl]="valueFC"
        [disabled]="customValues.length >= this.maxAllowedValues"
        [maxLength]="maxInputLength"
      />
      <mat-error *ngIf="valueFC.hasError('required')">{{
        valueRequiredErrorText
      }}</mat-error>
    </mat-form-field>
    <button
      mat-button
      [disabled]="
        keyFC.value.length < 1 ||
        valueFC.value.length < 1 ||
        customValues.length >= maxAllowedValues ||
        keyFC.hasError('duplicate')
      "
      (click)="onAddClick()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </form>

  <mat-list>
    <div
      class="list-item-wrapper"
      *ngFor="let cv of customValues; let i = index"
    >
      <mat-list-item>
        <div>
          <strong>{{ i + 1 }}:</strong>
        </div>
        <div class="text-area">{{ cv.key }}</div>
        <div class="text-area">{{ cv.value }}</div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <button mat-button (click)="onRemoveClick(cv.key)">
        <mat-icon>remove</mat-icon>
      </button>
    </div>
  </mat-list>
</mat-dialog-content>

<mat-dialog-actions class="button-row">
  <div class="reset-btn">
    <button
      mat-button
      [disabled]="customValues.length < 1"
      (click)="onResetClick()"
    >
      {{ resetBtnLabel }}
    </button>
  </div>
  <div class="done-btn">
    <button mat-button (click)="onDoneClick()">{{ doneBtnLabel }}</button>
  </div>
</mat-dialog-actions>
