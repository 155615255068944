import { Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class PartnerLegitimationMapper {
  constructor(private readonly sanitizer: DomSanitizer) {}

  public mapBlobToSafeDataUrl(blob: Blob | null): SafeUrl | null {
    if (!blob) {
      return null;
    }
    const dataUrl: string = window.URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustUrl(dataUrl);
  }
}
