import { default as STRINGS } from "../../../assets/strings.json";

type CustomerFieldLabels = Record<string, string>;
const customerFieldLabels: CustomerFieldLabels = STRINGS.customerFieldLabels;

export class StringsUtil {
  public static format(
    formatString: string,
    ...args: (string | null)[]
  ): string {
    const argumentValues = args.map<string>((x) => (x === null ? "" : x));

    return formatString.replace(/{(\d+)}/g, function (match, number) {
      return typeof argumentValues[number] != "undefined"
        ? argumentValues[number]
        : match;
    });
  }

  public static getTitle(id: string): string {
    return customerFieldLabels[id] || id;
  }
}
