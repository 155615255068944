export interface Environment {
  production: boolean;
  api: PartnerApi;
  envTile: string;
  qrBasicUrl: string;
}

/** The endpoints provided by the partner API. */
export interface PartnerApi {
  auth: {
    /**
     * Not parameterized.
     */
    login: string;
  };
  legitimation: {
    getAll: string;
    /**
     * Not parameterized.
     */
    create: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     */
    details: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The image type.
     */
    image: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     *
     * {1}: The video type.
     */
    video: string;

    /**
     * Parameters:
     *
     * {0}: The legitimation ID.
     */
    zip: string;
  };
}

/** Creates an environment API based on a coreHost. */
export const createEnvironmentApi = (coreHost: string): PartnerApi => {
  const coreUrl = coreHost + "/qid/v1";
  return {
    auth: {
      login: `${coreHost}/oauth/token`,
    },
    legitimation: {
      getAll: `${coreUrl}/legitimations`,
      create: `${coreUrl}/legitimations`,
      details: `${coreUrl}/legitimations/{0}`,
      image: `${coreUrl}/legitimations/{0}/files/images/{1}`,
      video: `${coreUrl}/legitimations/{0}/files/videos/{1}`,
      zip: `${coreUrl}/legitimations/{0}/files/zip`,
    },
  };
};
