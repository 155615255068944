import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StartPageComponent } from "./start-page/start-page.component";
import { NotFoundComponent } from "./not-found.component";
import { AppRoutes } from "./app.routes";
import { AuthGuard } from "./login/auth.guard";

const ANGULAR_ROUTES: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: StartPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.LEGITIMATION,
    loadChildren: () =>
      import("./partner-legitimations/partner-legitimations.module").then(
        (m) => m.LegitimationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.LOGIN,
    loadChildren: () =>
      import("./login/partner-login.module").then((m) => m.LoginModule),
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ANGULAR_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
