<h2 mat-dialog-title>{{ createTitle }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="input-field-wrapper">
    <form [formGroup]="creationForm">
      <ng-container *ngFor="let form of inputForms">
        <ng-container *ngIf="form.type === 'select' && form.values">
          <mat-form-field>
            <mat-label> {{ form.title }} </mat-label>
            <mat-select
              matInput
              [formControlName]="form.id"
              (valueChange)="qesSelected($event, form.id)"
            >
              <mat-option *ngFor="let val of form.values" [value]="val">
                {{ getLabelFromEnum(val) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="form.type !== 'select'">
          <mat-form-field>
            <mat-label>{{ form.title }}</mat-label>
            <input
              matInput
              [disabled]="form.disabled"
              [type]="form.type"
              [formControlName]="form.id"
            />
          </mat-form-field>
        </ng-container>
      </ng-container>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="button-row">
  <div class="add-pdf-custom-values-btns">
    <input
      multiple
      type="file"
      style="display: none"
      (change)="selectedFiles($event)"
      #fileInput
    />
    <button
      mat-stroked-button
      [matTooltip]="
        !creationForm.controls.telephoneNumber.dirty ||
        !creationForm.controls.emailAddress.dirty
          ? 'QES Product, Email and Phone Number Required'
          : ''
      "
      color="primary"
      [disabled]="
        (creationForm.controls.telephoneNumber.disabled &&
          creationForm.controls.emailAddress.disabled) ||
        !creationForm.controls.telephoneNumber.dirty ||
        !creationForm.controls.emailAddress.dirty ||
        isCurrentlyLoading ||
        hasErrorLoading
      "
      (click)="fileInput.click()"
    >
      {{ attachPdfsButton }}
    </button>
    <button
      mat-stroked-button
      color="primary"
      [matBadge]="customValuesList.length"
      [matBadgeHidden]="customValuesList.length < 1"
      [disabled]="isCurrentlyLoading || hasErrorLoading"
      (click)="openAddCustomValueDialog()"
    >
      {{ addCustomValuesButton }}
    </button>
  </div>

  <div class="save-cancel-btns">
    <button
      [disabled]="isCurrentlyLoading || hasErrorLoading"
      mat-button
      (click)="createLegitimation()"
    >
      {{ createButtonSave }}
      <mat-spinner
        *ngIf="isCurrentlyLoading && !hasErrorLoading"
        [diameter]="18"
        style="display: inline-block"
      ></mat-spinner>
    </button>
    <button class="button-cancel" mat-button [mat-dialog-close]="undefined">
      {{ createButtonCancel }}
    </button>
  </div>
</mat-dialog-actions>

<app-error *ngIf="hasErrorLoading">{{ errorMsg }}</app-error>
