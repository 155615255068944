import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { AppRoutes } from "../../app.routes";
import { map, shareReplay, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  loggedIn$: BehaviorSubject<boolean>;

  readonly STORAGE_USERNAME = "username";
  readonly STORAGE_PASSWORD = "password";

  constructor(private http: HttpClient, private router: Router) {
    this.loggedIn$ = new BehaviorSubject<boolean>(this.initIsLoggedIn());
  }

  public isLoggedIn(): boolean {
    return this.loggedIn$.value;
  }

  public login(username: string, password: string): Observable<void> {
    return this.postForToken(username, password).pipe(
      tap((response) => {
        this.setValues(username, password);
        this.loggedIn$.next(true);
      }),
      shareReplay(1, 180),
      /* Don't expose access token unnecessarily. */
      map(() => {})
    );
  }

  public logout(): void {
    this.clearSession();
    this.loggedIn$.next(false);
    this.router.navigate([AppRoutes.LOGIN]);
  }

  public setValues(username: string, password: string): void {
    sessionStorage.setItem(this.STORAGE_USERNAME, username);
    sessionStorage.setItem(this.STORAGE_PASSWORD, password);
  }

  public getUsername(): string {
    const username = sessionStorage.getItem(this.STORAGE_USERNAME);
    return username != null ? username : "";
  }

  public getPassword(): string {
    const password = sessionStorage.getItem(this.STORAGE_PASSWORD);
    return password != null ? password : "";
  }

  public getClientAuthorizationHeader(): string {
    const decoded = `${this.getUsername()}:${this.getPassword()}`;
    return "Basic " + btoa(decoded);
  }

  private initIsLoggedIn(): boolean {
    return this.getUsername().length > 0;
  }

  private getAuthorizationHeader(username: string, password: string): string {
    const decoded = `${username}:${password}`;
    return "Basic " + btoa(decoded);
  }

  private createLoginRequest(
    username: string,
    password: string
  ): URLSearchParams {
    const request = new URLSearchParams();
    request.append("username", username);
    request.append("password", password);
    request.append("grant_type", "client_credentials");
    return request;
  }

  private postForToken(username: string, password: string): Observable<any> {
    return this.http.post<any>(
      environment.api.auth.login,
      this.createLoginRequest(username, password).toString(),
      this.getTokenRequestOptions(username, password)
    );
  }

  private getTokenRequestOptions(username: string, password: string) {
    return {
      headers: {
        Authorization: this.getAuthorizationHeader(username, password),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }

  private clearSession() {
    sessionStorage.removeItem(this.STORAGE_USERNAME);
    sessionStorage.removeItem(this.STORAGE_PASSWORD);
  }
}
