import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PartnerLegitimationAdapter } from "./partner-legitimation.adapter";
import { LegitimationImageType, VideoType } from "../model/Legitimation";
import { SafeUrl } from "@angular/platform-browser";
import { map } from "rxjs/operators";
import LegitimationPreview from "../model/LegitimationPreview";

@Injectable({
  providedIn: "root",
})
export class PartnerLegitimationService {
  constructor(
    private readonly partnerLegitimationAdapter: PartnerLegitimationAdapter
  ) {}

  public getLegitimation(id: string): Observable<string> {
    return this.partnerLegitimationAdapter.getLegitimation(id) || null;
  }

  public postLegitimationDetails(body: object): Observable<string> {
    return this.partnerLegitimationAdapter.postLegitimation(body);
  }

  public getLegitimationImage(
    id: string,
    imageType: LegitimationImageType
  ): Observable<SafeUrl | null> {
    return this.partnerLegitimationAdapter.getLegitimationImage(id, imageType);
  }

  public getLegitimationVideoUrl(id: string, videoType: VideoType): string {
    return this.partnerLegitimationAdapter.getVideoUrl(id, videoType);
  }

  public getLegitimationFilesZip(id: string): Observable<Blob | null> {
    return this.partnerLegitimationAdapter.getLegitimationFilesZip(id);
  }

  public getAllLegitimations(): Observable<LegitimationPreview[]> {
    return this.partnerLegitimationAdapter.getAllLegitimations().pipe(
      map((res) => {
        return res.map((l) => {
          const a: LegitimationPreview = {
            id: l.id,
            legitimationStart: new Date(l.legitimationStart),
            currentStatus: l.currentStatus,
            partnerShortName: l.partnerShortName,
          };
          return a;
        });
      })
    );
  }
}
